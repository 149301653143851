/* eslint-disable import/no-unresolved */
<template>
  <section id="live-speakers">
    <p>Gerenciamento de congressos</p>
    <div>
      <add-new-congress
        :is-add-new-congress-sidebar-active.sync="isAddNewCongressSidebarActive"
        @onSaved="onSaved($event)"
        @close="isAddNewCongressSidebarActive = false"
      />

      <edit-congress
        :is-edit-congress-sidebar-active.sync="isEditCongressSidebarActive"
        :data="rowData"
        @onSaved="onSaved($event)"
        @close="isEditCongressSidebarActive = false"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>congressos</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchValue"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewCongressSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar congresso</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="overflow-auto">
          <b-table
            id="mytable"
            ref="mytable"
            hover
            :items="items"
            :fields="tableColumnsCongress"
            :filter="searchValue"
            primary-key="id"
            :total-rows="rows"
            :per-page="perPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :current-page="currentPage"
            show-empty
            empty-text="Nenhuma informação encontrada"
            busy.sync="isBusy"
            empty-filtered-text="Nenhum registro filtrado"
          >
            <template slot="empty">
              <div
                v-if="isBusy"
                class="text-center"
              >
                <b-spinner class="align-middle" />
              </div>
              <div
                v-else
                class="text-center"
              >
                Nenhuma informação encontrada
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item :href="`/administrador/eventos/${data.item.path_event}/${data.item.id}`">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Atividades</span>
                </b-dropdown-item>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon
                    icon="EditIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="remove(data.item)">
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Deletar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }} palestrantes</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import AddNewCongress from './AddNewCongress.vue'
import EditCongress from './EditCongress.vue'
import CongressService from './congressService'
import { mapActions } from 'vuex'

export default {

  components: {
    AddNewCongress,
    EditCongress,
    // EditSpeaker,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewCongressSidebarActive = ref(false)
    const isEditCongressSidebarActive = ref(false)
    return {
      isAddNewCongressSidebarActive,
      isEditCongressSidebarActive,
    }
  },
  data() {
    return {
      items: [],
      tableColumnsCongress: [
        {
          key: 'id',
          label: 'Código',
          sortable: true
        },
        {
          key: 'title',
          label: 'Titulo',
          sortable: true
        },
        {
          key: 'descriptions',
          label: 'Descrição',
          sortable: false,
          formatter(v, k, item) {
            return `${v.substring(0, 130)}...`
          }
        },
        {
          key: 'amount',
          label: 'Valor',
          sortable: true,
          formatter(v, k, item) {
            return `R$ ${v.replace('.', ',')}`
          }
        },
        {
          key: 'ready_for_sale',
          label: 'Pronto Para Venda',
          sortable: true,
          formatter(v, k, item) {
            return v.toString() === '1' ? 'Sim' : 'Não'
          }
        },
        {
          key: 'ready_to_start',
          label: 'Pronto Para Iniciar',
          sortable: true,
          formatter(v, k, item) {
            return v.toString() === '1' ? 'Sim' : 'Não'
          }
        },
        {
          key: 'actions',
          label: 'Ações',
          sortable: false,
        }
      ],
      rows: 0,
      perPage: 10,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prevPage: this.currentPage - 1,
      sortBy: 'id',
      sortDesc: true,
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      searchValue: '',
      isBusy: false,
      isRemoving: false,
      rowData: {}
    }
  },
  computed: {

  },
  watch: {

  },
  beforeMount() {
    this.listCongress()
  },
  methods: {
    onSaved(saved) {
      this.listCongress()
    },
    listCongress() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      this.items = []
      this.isBusy = true
      CongressService().fetchCongress(params).then(data => {
        this.items = data.response.congress
        this.rows = data.response.congress.length
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.title = data.title
      this.rowData.description = data.descriptions
      this.rowData.price = data.amount
      this.rowData.readyForSale = data.ready_for_sale
      this.rowData.readyToStart = data.ready_to_start
      this.isEditCongressSidebarActive = true
    },
    remove(data) {
      this.$swal({
        title: 'Deletar congresso?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => CongressService().deleteCongress(data.id).then(response => {
          if (response.status === 200) {
            const new_items = this.items.filter((v) => v.id !== data.id)
            this.items = new_items
          }
        }).catch(err => {
          this.$swal.fire(
            'Deletado!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Congresso deletado com sucesso.',
            'success'
          )
        }
      })
    }
  },
}
</script>

<style lang="scss">
</style>
