import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function congressService() {
  const fetchCongress = async (queryParams) => {
    const res = await axios.get('/congress', { params: queryParams })

    return res.data
  }

  const sendCongress = async (form) => {
    const res = await axios.post('/congress', form, {
      headers: { 'content-type': 'multipart/form-data' },
      responseType: 'json'
    })

    return res.status
  }

  const updateCongress = async (id, form) => {
    const res = await axios.post(`/congress/admin/${id}`, form, {
      headers: { 'content-type': 'multipart/form-data' },
      responseType: 'json'
    })

    return res.status
  }

  const deleteCongress = async (id) => {
    const res = await axios.delete(`/congress/admin/${id}`)

    return res.data
  }

  return {
    fetchCongress,
    sendCongress,
    updateCongress,
    deleteCongress
  }
}
